import { Component, inject, Inject, Input, OnInit } from "@angular/core";
import { serviceStatus } from "../../../core/utils/tab.util";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Dialog } from "@angular/cdk/dialog";
import { SuccessComponent } from "../success/success.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-changed-confirmed-modal",
  template: `
    <!-- A full-screen semi-transparent overlay for the modal -->
    <div class="w-full bg-gray-800 bg-opacity-50">
      <!-- Modal Card -->
      <div class="bg-white rounded shadow p-4 w-full">
        <h2 class="text-xl font-bold mb-4">Change Confirmed Time</h2>

        <form (ngSubmit)="onChangedConfirmed()">
          <!-- Select Date -->
          <div class="w-full my-4">
            <label for="selectedDate" class="block mb-1 font-semibold"
              >Select Date</label
            >
            <input
              id="selectedDate"
              type="date"
              [(ngModel)]="selectedDate"
              (ngModelChange)="fetchAvailableSlots()"
              name="selectedDate"
              class="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>

          <!-- Preferred Slot Selection -->
          <div class="mb-4">
            <label for="selectedSlot" class="block mb-1 font-semibold"
              >Select Preferred Slot</label
            >
            <select
              id="selectedSlot"
              [(ngModel)]="selectedSlot"
              name="selectedSlot"
              class="w-full border border-gray-300 rounded px-3 py-2"
              required
            >
              <option [ngValue]="null" disabled selected>
                -- Choose a slot --
              </option>
              <option
                *ngFor="let slot of all_slots"
                [ngValue]="slot"
              >
                ({{ slot.from_timing }} -
                {{ slot.to_timing}})
              </option>
            </select>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end space-x-2">
            <button
              type="button"
              (click)="onClose()"
              class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
            >
              Close
            </button>
            <button
            [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
              type="submit"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  `,
})
export class ChangedConfirmedModalComponent {
  selectedDate: string | null = null;
  selectedSlot: any = null;
  baseUrl = environment.url;
  token:any; 
  all_slots:any = [];
  private snackBar = inject(MatSnackBar);

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog:Dialog,
    private dialogRef: MatDialogRef<ChangedConfirmedModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem('token')
    // any initialization you need
  }

  fetchAvailableSlots(): void {
    if (!this.selectedDate) return; // Avoid calling API when date is null
    this.http
      .get(`${this.baseUrl}/get-all-slots-by-date?validity_date=${this.selectedDate}`)
      .subscribe({
        next: (res: any) => {
          console.log("Available slots====>:", res.data[0].customer_slots);
          if (res.success) {
            this.all_slots = res.data[0].customer_slots;
          }
        },
        error: (err) => {
          console.error("Error fetching slots:", err);
        },
      });
  }

  openSuccess() {
    this.dialog.open(SuccessComponent);
  }

  onChangedConfirmed(): void {
    if (!this.selectedDate || !this.selectedSlot) {
      return; // show a validation message or handle empty fields
    }

    const payload = {
      id: this.data.id,
      date: this.selectedDate,
      start: this.selectedSlot.from_timing,
      end: this.selectedSlot.to_timing
    };


    // Example API call
    this.http.patch(`${this.baseUrl}/confirm-request`, payload,  {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (res:any) => {
        console.log("Service confirmed:", res);
        // Navigate or close modal as desired
        if(res['success']){
          this.dialogRef.close();
          // this.router.navigate(["/services"]);
          this.openSuccess();
        }
      },
      error: (err) => {
        console.error("Error confirming service:", err);
        if(err.error.errors) {
          this.snackBar.open(err.error.errors,'',{duration:3000});
        } else {
          this.snackBar.open(err.error.error,'',{duration:3000});
        }
        // Show error message or handle accordingly
      },
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  disable(){
    let is_disabled = true;
    if (!this.selectedDate || !this.selectedSlot) {
      is_disabled = true
    }else{
      is_disabled = false
    }
   return is_disabled;
  }
}
