import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.component.html",
})
export class TabsComponent {
  @Input() itemCounts: any = {};
  @Input() statuses: { label: string; value: string }[] = [];
  @Input() currentStatus!: { label: string; value: string };
  @Output() statusChange = new EventEmitter<{ label: string; value: string }>();

  selectTab(status: { label: string; value: string }) {
    this.statusChange.emit(status);
  }
}
