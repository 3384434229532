import { Dialog } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent {
  constructor(private router: Router,private dialog: Dialog) {}
  success() {
    this.dialog.closeAll();
    this.router.navigate(["/services"]);
  }
}
