import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { TabDataService } from './services/tab-data.service';
import { LocalStorageService } from './services/localstorage.service';

@NgModule({
  imports: [
    HttpClientModule, // Provides HTTP services globally
  ],
  providers: [
    TabDataService,
    LocalStorageService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only in AppModule.');
    }
  }
}
