import { Component, inject, Inject, Input, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Dialog } from "@angular/cdk/dialog";
import { SuccessComponent } from "../success/success.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-complete-modal",
  template: ` <div class="w-full bg-gray-800 bg-opacity-50">
    <!-- Modal Card -->
    <div class="bg-white rounded shadow p-4 w-full">
    

      <form (ngSubmit)="onComplete()">
        <!-- Select gsn -->
        <div class="w-full my-4">
          <label for="gsn" class="block mb-1 font-semibold"
            >GSN (Optional)</label
          >
          <input
            id="gsn"
            type="text"
            [(ngModel)]="gsn"
            name="gsn"
            class="w-full border border-gray-300 rounded px-3 py-2"
            required
          />
        </div>

        <!-- Select cost -->
        <div class="mb-4">
          <label for="cost" class="block mb-1 font-semibold"
            >Enter Price</label
          >
          <input
            id="cost"
            type="number"
            [(ngModel)]="cost"
            name="cost"
            class="w-full border border-gray-300 rounded px-3 py-2"
            required
          />
        </div>

        <!-- Select comment-->
        <div class="mb-4">
          <label for="comment" class="block mb-1 font-semibold"
            >Enter Comment</label
          >
          <textarea
            id="comment"
            type="text"
            [(ngModel)]="comment"
            name="comment"
            class="w-full border border-gray-300 rounded px-3 py-2"
            required
          ></textarea>
        </div>

        <div class="mb-4 inline-flex space-x-2">
         
          <input
            id="is_replacement"
            type="checkbox"
            [(ngModel)]="is_replacement"
            name="is_replacement"
            class="border border-gray-300 rounded h-4 w-4"
            required
          />

          <label for="is_replacement" class="block mb-1 font-semibold"
            >Mark for replacement</label
          >
        </div>

        <!-- Action Buttons -->
        <div class="flex justify-end space-x-2">
          <button
            type="button"
            (click)="onClose()"
            class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
          >
            Close
          </button>
          <button
          [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
            type="submit"
          >
            Complete
          </button>
        </div>
      </form>
    </div>
  </div>`,
})
export class CompleteModalComponent implements OnInit {
  baseUrl = environment.url;
  gsn = "";
  cost:number = 0;
  comment = "";
  is_replacement = 0;
  token:any;
  private snackBar = inject(MatSnackBar);

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog:Dialog,
    private dialogRef: MatDialogRef<CompleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem("token");
  }

  onClose(): void {
    this.dialogRef.close();
  }

  openSuccess() {
    this.dialog.open(SuccessComponent);
  }

  onComplete(): void {
    

    const payload = {
      id: this.data.id,
      gsn: this.gsn,
      price: this.cost,
      price_comment: this.comment,
      is_replacement: this.is_replacement ? 1 : 0,
    };

    // Example API call
    this.http.patch(`${this.baseUrl}/complete-request`, payload,  {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (res:any) => {
        console.log("Service confirmed:", res);
        // Navigate or close modal as desired
        if(res['success']){
          this.dialogRef.close();
          // this.router.navigate(["/services"]);
          this.openSuccess();
        }
      },
      error: (err) => {
        if(err.error.errors) {
          this.snackBar.open(err.error.errors,'',{duration:3000});
        } else {
          this.snackBar.open(err.error.error,'',{duration:3000});
        }
        console.error("Error confirming service:", err);
        // Show error message or handle accordingly
      },
    });
  }

  disable(){
    let is_disabled = true;
    if (this.cost < 0 || !this.comment) {
      is_disabled = true
    }else{
      is_disabled = false
    }
   return is_disabled;
  }
}
