import { Component, inject, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { Dialog } from "@angular/cdk/dialog";
import { SuccessComponent } from "../success/success.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-confirm-modal",
  // All HTML (template) inlined here
  template: `
    <!-- A full-screen semi-transparent overlay for the modal -->
    <div class="w-full bg-gray-800 bg-opacity-50">
      <!-- Modal Card -->
      <div class="bg-white rounded shadow p-4 w-full">
        <h2 class="text-xl font-bold mb-4">Confirm The Service</h2>

        <div class="border-t border-gray-200 pt-3">
          <h3 class="text-lg font-semibold text-gray-800">Preferred Slots:</h3>
          <ul class="px-2">
            <li
              *ngFor="let slot of data.preferred_slots"
              class="inline-flex space-x-1"
            >
              <p>
                <span class="font-semibold">Date:</span>
                {{ slot.preferred_date }},
              </p>

              <p>
                <span class="font-semibold">Time:</span>
                {{ slot.preferred_slot_start }}-{{ slot.preferred_slot_end }}
              </p>
            </li>
          </ul>
        </div>

        <form (ngSubmit)="onConfirm()">
          <!-- Select Date -->
          <div class="w-full my-4">
            <label for="selectedDate" class="block mb-1 font-semibold"
              >Select Date</label
            >
            <input
              id="selectedDate"
              type="date"
              [(ngModel)]="selectedDate"
              (ngModelChange)="fetchAvailableSlots()"
              name="selectedDate"
              class="w-full border border-gray-300 rounded px-3 py-2"
              required
            />
          </div>

          <!-- Preferred Slot Selection -->
          <div class="mb-4">
            <label for="selectedSlot" class="block mb-1 font-semibold"
              >Select Preferred Slot</label
            >
            <select
              id="selectedSlot"
              [(ngModel)]="selectedSlot"
              
              name="selectedSlot"
              class="w-full border border-gray-300 rounded px-3 py-2"
              required
            >
              <option [ngValue]="null" disabled selected>
                -- Choose a slot --
              </option>
              <option
                *ngFor="let slot of all_slots"
                [ngValue]="slot"
              >
                ({{ slot.from_timing }} -
                {{ slot.to_timing}})
              </option>
            </select>
          </div>

          <!-- Action Buttons -->
          <div class="flex justify-end space-x-2">
            <button
              type="button"
              (click)="onClose()"
              class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
            >
              Close
            </button>
            <button
            [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
              type="submit"
              
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  `,
  styles: [],
})
export class ConfirmModalComponent implements OnInit {
  // Example data bound to the form
  selectedDate: string | null = null;
  selectedSlot: any = null;
  baseUrl = environment.url;
  token:any;
  all_slots:any = [];
  private snackBar = inject(MatSnackBar);

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog:Dialog,
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    // any initialization you need
    this.token = localStorage.getItem("token");
  }

  fetchAvailableSlots(): void {
    if (!this.selectedDate) return; // Avoid calling API when date is null
    this.http
      .get(`${this.baseUrl}/get-all-slots-by-date?validity_date=${this.selectedDate}`)
      .subscribe({
        next: (res: any) => {
          console.log("Available slots====>:", res.data[0].customer_slots);
          if (res.success) {
            this.all_slots = res.data[0].customer_slots;
          }
        },
        error: (err) => {
          console.error("Error fetching slots:", err);
        },
      });
  }

  openSuccess() {
    this.dialog.open(SuccessComponent);
  }

  onConfirm(): void {
    
    const payload = {
      id: this.data.id,
      date: this.selectedDate,
      start: this.selectedSlot.from_timing,
      end: this.selectedSlot.to_timing

    };

    
    // Example API call
    this.http.patch(`${this.baseUrl}/confirm-request`, payload,  {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (res:any) => {
        console.log("Service confirmed:", res);
        // Navigate or close modal as desired
        if(res['success']){
          this.dialogRef.close();
          // this.router.navigate(["/services"]);
          this.openSuccess();
        }
      },
      error: (err) => {
        console.error("Error confirming service:", err);
        if(err.error.errors) {
          this.snackBar.open(err.error.errors,'',{duration:3000});
        } else {
          this.snackBar.open(err.error.error,'',{duration:3000});
        }
      },
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  disable(){
    let is_disabled = true;
    if (!this.selectedDate || !this.selectedSlot) {
      is_disabled = true
    }else{
      is_disabled = false
    }
   return is_disabled;
  }
}
