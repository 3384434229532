import { Component } from '@angular/core';
import { LocalStorageService } from 'src/app/core/services/localstorage.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  technician:any;
  constructor(private localStorageService: LocalStorageService, private router: Router) {
      this.technician = this.localStorageService.getItem("user-info")
   }

   logout(){
     this.localStorageService.getItem("user-info")
     this.router.navigate(["/"]);
     sessionStorage.removeItem('yourKey');
   }
}
