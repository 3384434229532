import { Component, inject, Inject, Input, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { SuccessComponent } from "../success/success.component";
import { Dialog } from "@angular/cdk/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-complete-modal",
  template: ` <div class="w-full bg-gray-800 bg-opacity-50">
    <!-- Modal Card -->
    <div class="bg-white rounded shadow p-4 w-full">
      <h2 class="text-xl font-bold mb-4">Cancellation Action</h2>

      <form (ngSubmit)="onCancel()">

       <!-- Select comment-->
       <div class="mb-4">
          <label for="reason" class="block mb-1 font-semibold"
            >Enter Reason</label
          >
          <textarea
            id="reason"
            type="text"
            [(ngModel)]="reason"
            name="reason"
            class="w-full border border-gray-300 rounded px-3 py-2"
            required
          ></textarea>
        </div>

       <!-- Action Buttons -->
       <div class="flex justify-end space-x-2">
          <button
            type="button"
            (click)="onClose()"
            class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
          >
            Close
          </button>
          <button
          [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
            type="submit"
          >
            Cancel the service
          </button>
        </div>

    
      </form>
    </div>
  </div>`,
})
export class CancelModalComponent implements OnInit {
  baseUrl = environment.url;
  reason='';
  token:any;
  
  private snackBar = inject(MatSnackBar);
  constructor(  
    private router: Router,
    private http: HttpClient,
    private dialog:Dialog,
    private dialogRef: MatDialogRef<CancelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.token = localStorage.getItem("token");
  }

  onClose(): void {
    this.dialogRef.close();
  }
  openSuccess() {
    this.dialog.open(SuccessComponent);
  }

  onCancel(): void {
    if (!this.reason) {
      return; // show a validation message or handle empty fields
    }

    const payload = {
      id: this.data.id,
      reason_for_cancelation:this.reason
    };

    // Example API call
    this.http.patch(`${this.baseUrl}/cancel-request`, payload, {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (res:any) => {
        console.log("Service confirmed:", res);
        if(res['success']){
          this.dialogRef.close();
          this.openSuccess();
          // this.router.navigate(["/services"]);
        }
      },
      error: (err) => {
        console.error("Error confirming service:", err);
        if(err.error.errors) {
          this.snackBar.open(err.error.errors,'',{duration:3000});
        } else {
          this.snackBar.open(err.error.error,'',{duration:3000});
        }
      },
    });
  }

  disable(){
    let is_disabled = true;
    if (!this.reason) {
      is_disabled = true
    }else{
      is_disabled = false
    }
   return is_disabled;
  }
}
