// src/app/shared/shared.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Import your shared components here
import { TabsComponent } from './components/tabs/tabs.component';
import { TabContentComponent } from './components/tab-content/tab-content.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { ConfirmModalComponent } from './components/modals/confirm-modal.component';
import { RescheduleModalComponent } from './components/modals/reschedule-modal.component';
import { ChangedConfirmedModalComponent } from './components/modals/changed-confirmed-modal.component';
import { UpdateCostModalComponent } from './components/modals/update-cost-modal.component';
import { UpdateReplacementModalComponent } from './components/modals/update-replacement-modal.component';
import { FormsModule } from '@angular/forms';
import { CompleteModalComponent } from './components/modals/complete-modal.component';
import { CancelModalComponent } from './components/modals/cancel-modal.component';
import { SuccessComponent } from './components/success/success.component';

@NgModule({
  declarations: [
    TabsComponent,
    TabContentComponent,
    BottomSheetComponent,
    ConfirmModalComponent,
    CompleteModalComponent,
    RescheduleModalComponent,
    ChangedConfirmedModalComponent,
    UpdateCostModalComponent,
    UpdateReplacementModalComponent,
    CancelModalComponent,
    SuccessComponent
  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    TabsComponent,
    TabContentComponent,
    BottomSheetComponent,
    ConfirmModalComponent,
    CompleteModalComponent,
    RescheduleModalComponent,
    ChangedConfirmedModalComponent,
    UpdateCostModalComponent,
    UpdateReplacementModalComponent,
    CancelModalComponent
  ]
})
export class SharedModule { }
