import { Component, Input, OnInit } from "@angular/core";
import { serviceStatus } from "../../../core/utils/tab.util";
import { Router } from "@angular/router";
import { SingleServiceDetailsService } from "src/app/core/services/single-service-details.service";

@Component({
  selector: "app-tab-content",
  templateUrl: "./tab-content.component.html",
})
export class TabContentComponent {
  @Input() items: any[] = [];
  @Input() loading: boolean = true;
  @Input() currentStatus!: { label: string; value: string };

  constructor(private router: Router, private singleService: SingleServiceDetailsService) {}
  serviceStatus = serviceStatus;

  viewDetails(item: any) {
    this.singleService.singleServiceDetails.next(item);
    console.log("item====>", item)
    this.router.navigate(["/services/preview"], { state: { data: item } });
  }
  openDialer(phoneNumber: string) {
    window.location.href = `tel:${phoneNumber}`;
  }
}
