import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { serviceStatus } from 'src/app/core/utils/tab.util';
import { ConfirmModalComponent } from '../modals/confirm-modal.component';
import { CompleteModalComponent } from '../modals/complete-modal.component';
import { RescheduleModalComponent } from '../modals/reschedule-modal.component';
import { ChangedConfirmedModalComponent } from '../modals/changed-confirmed-modal.component';
import { CancelModalComponent } from '../modals/cancel-modal.component';
import { UpdateCostModalComponent } from '../modals/update-cost-modal.component';
import { UpdateReplacementModalComponent } from '../modals/update-replacement-modal.component';
import { TabDataService } from 'src/app/core/services/tab-data.service';
import { SingleServiceDetailsService } from 'src/app/core/services/single-service-details.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
//   styleUrls: ['./bottom-sheet.component.css']
})
export class BottomSheetComponent  {
  serviceStatus:any = serviceStatus;

  constructor(
    private serviceDetails: SingleServiceDetailsService,
    private dialog: MatDialog,
    private tabDataService: TabDataService,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
    this.data = data
  }

  // ngOnInit(): void {
  //   console.log("data====>", this.data)
  // }

  closeBottomSheet(): void {
    this.bottomSheetRef.dismiss();
  }
  
  openConfirmModel():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
    
  }

  fetchData() {
    let tech_id = localStorage.getItem('user-info');
    let obj = (JSON.parse(`${tech_id}`));
    this.tabDataService.fetchData({
      status:"all",
      page:1,
      size:200,
      technician_id: obj._id.$oid
    }).subscribe(
      (data: any) => {
        if (data.success) {
          console.log(this.data.id)
          data.data.forEach((service:any) => {
            if(service.id == this.data.id){
              this.serviceDetails.singleServiceDetails.next(service);
            }
          })
        }
      },
      (error: any) => {
        console.error("API error:", error);
      }
    );
  }

  openCompleteModel():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(CompleteModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }

  openChangeConfirmModel():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(ChangedConfirmedModalComponent,{
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }


  openRescheduleModel():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(RescheduleModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }

  openCancelModel():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(CancelModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }

  onUpdateCostModal():void {
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(UpdateCostModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }

  onUpdateReplacemenent():void{
    this.bottomSheetRef.dismiss();
    const dialogRef = this.dialog.open(UpdateReplacementModalComponent, {
      data: {
        preferred_slots : this.data.preferred_slots,
        id: this.data.id
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.fetchData();
    });
  }

}
