<div class="flex w-full">
  <button
    *ngFor="let status of statuses"
    (click)="selectTab(status)"
    class="flex-1 p-3 text-center border-b-2 transition-all"
    [class.border-blue-500]="currentStatus.value === status.value"
    [class.text-blue-500]="currentStatus.value === status.value"
    [class.text-gray-600]="currentStatus.value !== status.value"
  >
    {{ status.label }} ({{ itemCounts[status.value] || 0 }})
  </button>
</div>
