import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SingleServiceDetailsService {

  constructor() { }

  public singleServiceDetails:any = new BehaviorSubject({});
  serviceDetails = this.singleServiceDetails.asObservable();
}
