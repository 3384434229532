<div class="p-5 bg-white dark:bg-gray-900 rounded-t-2xl shadow-lg w-full">
  <!-- Drag Handle -->
  <div class="flex justify-center mb-2">
    <div class="w-12 h-1 bg-gray-400 rounded-full opacity-50"></div>
  </div>

  <!-- Title & Close Button -->
  <div class="flex justify-between items-center">
    <h2 class="text-lg font-semibold text-gray-800 dark:text-gray-200">Actions</h2>
    <button (click)="closeBottomSheet()" class="text-gray-500 hover:text-gray-700 dark:text-gray-400">
      <span class="material-symbols-outlined">close</span>
    </button>
  </div>

  <hr class="my-3 border-gray-200 dark:border-gray-700">

  <!-- Content -->
  <div class="text-gray-700 dark:text-gray-300 max-w-full space-y-2">

    <ng-container [ngSwitch]="data.status">

      <!-- Show Confirmed Slot if status is CONFIRMED -->
      <div *ngSwitchCase="serviceStatus.CONFIRMED">
        <ng-container *ngTemplateOutlet="confirmed; context: {}"></ng-container>
      </div>

      <!-- Show Preferred Slots if status is ASSIGNED -->
      <div *ngSwitchCase="serviceStatus.ASSIGNED">
        <ng-container *ngTemplateOutlet="assigned; context: {}"></ng-container>
      </div>

       <!-- Show Preferred Slots if status is ASSIGNED -->
       <div *ngSwitchCase="serviceStatus.COMPLETED">
        <ng-container *ngTemplateOutlet="completed; context: {}"></ng-container>
      </div>

      <div *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="default; context: {}"></ng-container>
      </div>

    </ng-container>
    
  </div>
</div>




<!-- Reusable Templates -->
<ng-template #completed >
  <button (click)="onUpdateCostModal()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
     Update Cost
  </button>

  <button (click)="onUpdateReplacemenent()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Initiate Replacement
  </button>

</ng-template>


<ng-template #confirmed >
  <button (click)="openCompleteModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Complete the service
  </button>

  <button (click)="openChangeConfirmModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Change Confirmed Timing
  </button>

  <button (click)="openRescheduleModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Assign Service Back To Admin
  </button>

  <button (click)="openCancelModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Cancel the service
 </button>
</ng-template>


<ng-template #assigned >
  <button (click)="openConfirmModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Confirm the service
  </button>

  <button (click)="openRescheduleModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Assign Service Back To Admin
  </button>

  <button (click)="openCancelModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Cancel the service
  </button>
</ng-template>


<ng-template #default >
  <button (click)="openCompleteModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Confirm the service
  </button>

  <button (click)="openChangeConfirmModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Change Confirmed Timing
  </button>

  <button (click)="openRescheduleModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
    Assign Service Back To Admin
  </button>

  <button (click)="openCancelModel()" class="text-gray-500 h-10 w-full text-center bg-white hover:text-gray-700 dark:text-gray-400">
     Cancel the service
  </button>
</ng-template>