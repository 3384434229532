import { Component, inject, Inject, Input, OnInit } from "@angular/core";
import { serviceStatus } from "../../../core/utils/tab.util";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SuccessComponent } from "../success/success.component";
import { Dialog } from "@angular/cdk/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-update-cost-modal",
  template: ` <div class="w-full bg-gray-800 bg-opacity-50">
  <!-- Modal Card -->
  <div class="bg-white rounded shadow p-4 w-full">
    <h2 class="text-xl font-bold mb-4">Update Price</h2>

    <form (ngSubmit)="onUpdateCost()">

    <div class="mb-4">
        <label for="price" class="block mb-1 font-semibold"
          >Enter Price</label
        >
        <input
          id="price"
          type="text"
          [(ngModel)]="price"
          name="price"
          class="w-full border border-gray-300 rounded px-3 py-2"
          required
        />
      </div>

     <!-- Select comment-->
     <div class="mb-4">
        <label for="reason" class="block mb-1 font-semibold"
          >Enter Reason</label
        >
        <textarea
          id="reason"
          type="text"
          [(ngModel)]="price_comment"
          name="reason"
          class="w-full border border-gray-300 rounded px-3 py-2"
          required
        ></textarea>
      </div>

     <!-- Action Buttons -->
     <div class="flex justify-end space-x-2">
        <button
          type="button"
          (click)="onClose()"
          class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
        >
          Close
        </button>
        <button
        [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
              type="submit"
          type="submit"
          class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
        >
          Submit
        </button>
      </div>

  
    </form>
  </div>
</div>`,
})
export class UpdateCostModalComponent {
  baseUrl = environment.url;
  price:number = 0;
  price_comment:string=""
  token:any;
  private snackBar = inject(MatSnackBar);

  constructor(
    private router: Router,  
    private dialogRef: MatDialogRef<UpdateCostModalComponent>,
    private http: HttpClient,
    private dialog: Dialog,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
      this.token = localStorage.getItem("token");
    }

 onUpdateCost(): void {

  const payload = {
    id: this.data.id,
    price: this.price,
    price_comment: this.price_comment
  };

  // Example API call
  this.http.patch(`${this.baseUrl}/update-cost`, payload,  {
    headers: new HttpHeaders({
      'Authorization': `Bearer ${this.token}`,
      'Content-Type': 'application/json'
    })
  }).subscribe({
    next: (res:any) => {
      console.log("Service confirmed:", res);
      // Navigate or close modal as desired
      if(res['success']){
        this.dialogRef.close();
        // this.router.navigate(["/services"]);
        this.dialog.open(SuccessComponent);
      }
    },
    error: (err) => {
      if(err.error.errors) {
        this.snackBar.open(err.error.errors,'',{duration:3000});
      } else {
        this.snackBar.open(err.error.error,'',{duration:3000});
      }
      console.error("Error confirming service:", err);
      // Show error message or handle accordingly
    },
  });
}

onClose(): void {
  this.dialogRef.close();
}


disable(){
  let is_disabled = true;
  if (!this.price || !this.price_comment) {
    is_disabled = true
  }else{
    is_disabled = false
  }
 return is_disabled;
}
}
