import { Component, inject, Inject, Input, OnInit } from "@angular/core";
import { serviceStatus } from "../../../core/utils/tab.util";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SuccessComponent } from "../success/success.component";
import { Dialog } from "@angular/cdk/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-reschedule-modal",
  template: ` <div class="w-full bg-gray-800 bg-opacity-50">
    <!-- Modal Card -->
    <div class="bg-white rounded shadow p-4 w-full">
      <p class="text-lg font-bold mb-4">Are you sure want to Assign the service back to admin?</p>

      <form (ngSubmit)="onReschedule()">
        <!-- Select comment-->
        <div class="mb-4">
          <textarea
            id="reason"
            type="text"
            placeholder="Reason for assiging back to admin"
            [(ngModel)]="reason"
            name="reason"
            class="w-full border border-gray-300 rounded px-3 py-2"
            required
          ></textarea>
        </div>

        <p class="text-red-500">Note - Rescheduling service will assign the service back to admin.</p>

        <!-- Action Buttons -->
        <div class="flex justify-end space-x-2">
          <button
            type="button"
            (click)="onClose()"
            class="bg-gray-300 hover:bg-gray-400 text-gray-700 px-4 py-2 rounded"
          >
            Close
          </button>
          <button
          [disabled]="disable()"
            class="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded
         disabled:bg-gray-200 disabled:cursor-not-allowed"
            type="submit"
            
          >
            Reschedule
          </button>
        </div>
      </form>
    </div>
  </div>`,
})
export class RescheduleModalComponent {
  reason = "";
  baseUrl = environment.url;
  token:any;
  private snackBar = inject(MatSnackBar);

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialog:Dialog,
    private dialogRef: MatDialogRef<RescheduleModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {
    this.token = localStorage.getItem("token");
  }
  onClose(): void {
    this.dialogRef.close();
  }

  onReschedule(): void {

    const payload = {
      id: this.data.id,
      reason_for_rescheduling: this.reason,
    };

    // Example API call
    this.http.patch(`${this.baseUrl}/reschedule-request`, payload,  {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json'
      })
    }).subscribe({
      next: (res:any) => {
        console.log("Service confirmed:", res);
        // Navigate or close modal as desired
        if(res['success']){
          this.dialogRef.close();
          // this.router.navigate(["/services"]);
          this.dialog.open(SuccessComponent);
        }
      },
      error: (err) => {
        if(err.error.errors) {
          this.snackBar.open(err.error.errors,'',{duration:3000});
        } else {
          this.snackBar.open(err.error.error,'',{duration:3000});
        }
        console.error("Error confirming service:", err);
        // Show error message or handle accordingly
      },
    });
  }

  disable(){
    let is_disabled = true;
    if (!this.reason) {
      is_disabled = true
    }else{
      is_disabled = false
    }
   return is_disabled;
  }

}
