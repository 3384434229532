export enum serviceStatus {
  ALL = 'all',
  CONFIRMED ='Confirmed',
  ASSIGNED = 'Assigned',
  CANCELED = 'Canceled',
  SCHEDULED = 'Scheduled',
  RESCHEDULED = 'Rescheduled',
  COMPLETED = 'Completed'
}

export const statuses = [
  { label: "Assigned", value: serviceStatus.ASSIGNED},
  { label: "Confirmed", value: serviceStatus.CONFIRMED },
  { label: "All", value: serviceStatus.ALL },
];

