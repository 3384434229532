<div *ngIf="loading" class="text-center text-gray-600">Loading...</div>

<div class="w-screen max-w-md mt-4" *ngIf="!loading">
  <div *ngIf="!loading && items.length === 0" class="text-center text-gray-500">
    No tasks available.
  </div>

  <div *ngFor="let item of items" class="w-full px-2">
    <div class="px-4 py-1 border border-black shadow my-2">
      <div>
        <h3 class="font-medium text-lg text-center">
          Service ID : {{ item.service_id }}
        </h3>
      </div>

      <!-- Customer Information -->
      <p>
        <span class="font-semibold">Customer Name:</span> {{ item.user.name }}
      </p>
      <p><span class="font-semibold">Mobile:</span> {{ item.order.mobile }}</p>

      <p>
        <span class="font-semibold">Address:</span> {{ item.address.house }},
        {{ item.address.street }}, {{ item.address.area }},
        {{ item.address.city }} - {{ item.address.pincode }}
      </p>

      <div class="border-t border-gray-200 my-2"></div>

      <!-- Product Information -->
      <p><span class="font-semibold">Product:</span> {{ item.product.name }}</p>
      <p>
        <span class="font-semibold">Issue Discription:</span>
        {{ item.product_issue_description }}
      </p>

      <p *ngIf="item.gsn">
        <span class="font-semibold">GSN:</span> {{ item.gsn }}
      </p>

      <div class="border-t border-gray-200 my-2"></div>

      <!-- Assigned Information -->
      <div *ngIf="item.assigned_at">
        <p>
          <span class="font-semibold">Assigned To:</span>
          {{ item.assigned_to.name }}
        </p>
        <p>
          <span class="font-semibold">Assigned At:</span>
          {{ item.assigned_at | date : "MMM d, y, h:mm a" }}
        </p>
      </div>

      <div *ngIf="item.confirmed_at">
        <p>
          <span class="font-semibold">Confirmed At:</span>
          {{ item.confirmed_at | date : "MMM d, y, h:mm a" }}
        </p>
      </div>

      <div *ngIf="item.cancelled_at">
        <p>
          <span class="font-semibold">Cancelled At:</span>
          {{ item.cancelled_at | date : "MMM d, y, h:mm a" }}
        </p>
      </div>

      <div *ngIf="item.completed_at">
        <p>
          <span class="font-semibold">Completed At:</span>
          {{ item.completed_at | date : "MMM d, y, h:mm a" }}
        </p>
      </div>

      <div *ngIf="item.rescheduled_at">
        <p>
          <span class="font-semibold">Rescheduled At:</span>
          {{ item.rescheduled_at | date : "MMM d, y, h:mm a" }}
        </p>
      </div>

      <div class="border-t border-gray-200 my-2"></div>

      <h3 class="font-semibold mb-2">Order details:</h3>
      <div *ngIf="item.order.id">
        <p>
          <span class="font-semibold">Order Id:</span> {{ item.order.order_id }}
        </p>
        <p>
          <span class="font-semibold">Order Mobile No:</span>
          {{ item.order.mobile }}
        </p>
        <p *ngIf="item.address && item.address.actual_area">
          <span class="font-semibold">Actual Area:</span>
          {{ item.address.actual_area }}
        </p>
        <p *ngIf="item.address && item.address.actual_area">
          <span class="font-semibold">Designated Area:</span>
          {{ item.address.designated_area }}
        </p>
        <p>
          <span class="font-semibold">Is replacement:</span>
          {{ !!item.is_replacement }}
        </p>
      </div>

      <div class="border-t border-gray-200 my-2"></div>

      <p><span class="font-semibold">Price:</span> {{ item.price }}</p>

      <div class="border-t border-gray-200 my-2"></div>

      <!-- Slot Section -->
      <ng-container [ngSwitch]="currentStatus.value">
        <!-- Show Confirmed Slot if status is CONFIRMED -->
        <div *ngSwitchCase="serviceStatus.CONFIRMED">
          <ng-container
            *ngTemplateOutlet="
              confirmedSlot;
              context: { slot: item.confirmed_slot }
            "
          ></ng-container>
        </div>

        <!-- Show Preferred Slots if status is ASSIGNED -->
        <div *ngSwitchCase="serviceStatus.ASSIGNED">
          <ng-container
            *ngTemplateOutlet="
              preferredSlots;
              context: { slots: item.preferred_slots }
            "
          ></ng-container>
        </div>

        <!-- For ALL, Show Confirmed Slot if present, else Preferred Slots -->
        <div *ngSwitchCase="serviceStatus.ALL">
          <ng-container
            *ngIf="item.confirmed_slot && item.confirm_at; else prefSlots"
          >
            <ng-container
              *ngTemplateOutlet="
                confirmedSlot;
                context: { slot: item.confirmed_slot }
              "
            ></ng-container>
          </ng-container>
          <ng-template #prefSlots>
            <ng-container
              *ngTemplateOutlet="
                preferredSlots;
                context: { slots: item.preferred_slots }
              "
            ></ng-container>
          </ng-template>
        </div>
      </ng-container>

      <div class="border-t my-3"></div>

      <p
        *ngIf="currentStatus.value === serviceStatus.ALL"
        class="text-yellow-600"
      >
        <span class="font-semibold">Status:</span>
        {{ item.status }}
      </p>

      <!-- Cancellation Reason -->
      <p
        *ngIf="
          item.status === serviceStatus.CANCELED && item.reason_for_cancelation
        "
        class="text-red-500"
      >
        <span class="font-semibold">Cancellation Reason:</span>
        {{ item.reason_for_cancelation }}
      </p>

      <p
        *ngIf="
          item.status === serviceStatus.RESCHEDULED &&
          item.reason_for_rescheduling
        "
        class="text-yellow-500"
      >
        <span class="font-semibold">Rescheduling Reason:</span>
        {{ item.reason_for_rescheduling }}
      </p>

      <p
        *ngIf="item.status === serviceStatus.COMPLETED && item.price_comment"
        class="text-yellow-600"
      >
        <span class="font-semibold">Completed Comment:</span>
        {{ item.price_comment }}
      </p>

      <div class="my-4 flex justify-between">
        <button
          class="px-4 py-2 bg-green-500 text-white rounded-lg"
          (click)="openDialer(item.order.mobile)"
          *ngIf="
            item.status === serviceStatus.ASSIGNED ||
            item.status === serviceStatus.CONFIRMED
          "
        >
          Call Customer
        </button>
        <button
          (click)="viewDetails(item)"
          class="px-4 py-2 bg-violet-500 text-white rounded-lg"
        >
          View Details
        </button>
      </div>
    </div>
  </div>

  <!-- Reusable Templates -->
  <ng-template #confirmedSlot let-slot="slot">
    <div *ngIf="slot">
      <h3 class="font-semibold mb-2">Confirmed Slot:</h3>
      <div class="bg-green-200 p-2 rounded-md">
        <p>
          <span class="font-semibold">Date:</span> {{ slot.preferred_date }}
        </p>
        <p>
          <span class="font-semibold">Time:</span>
          {{ slot.preferred_slot_start }} -
          {{ slot.preferred_slot_end }}
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template #preferredSlots let-slots="slots">
    <div *ngIf="slots.length > 0">
      <h3 class="font-semibold mb-2">Preferred Slots:</h3>
      <ul class="space-y-2">
        <li *ngFor="let slot of slots" class="bg-gray-200 p-2 rounded-md">
          <p>
            <span class="font-semibold">Date:</span> {{ slot.preferred_date }}
          </p>
          <p>
            <span class="font-semibold">Time:</span>
            {{ slot.preferred_slot_start }} - {{ slot.preferred_slot_end }}
          </p>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
