import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TabDataService {
  baseUrl = environment.url;
  constructor(private http: HttpClient) {}

  
  fetchData({
    status,
    technician_id,
    page,
    size
  }:{
    status:string,
    technician_id:string,
    page:number,
    size:number
  }): Observable<any[]> {
    let url = (status === 'all') ? `${this.baseUrl}/get-services-details?technician_id=${technician_id}&size=${size}&page=${page}` : `${this.baseUrl}/get-services-details?status=${status}&technician_id=${technician_id}&size=${size}&page=${page}`;
    return this.http.get<any[]>(url);
  }
}
